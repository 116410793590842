<template>
  <div id="analytics">
    <div
      class="analytics-product-search-info"
      v-if="productSearchInfo !== false"
      @click="$store.commit('analytics/setProductSearchInfo', false)"
    >
      <div class="analytics-product-search-info-inner" @click.stop>
        <h3>
          Search term: <span>{{ productSearchInfo.term }}</span>
        </h3>
        <div class="analytics-product-search-info-users">
          <div
            v-for="user in productSearchInfo.users"
            class="analytics-product-search-info-user"
            :key="'search-' + user"
          >
            {{ getUserNameById(user) }}
            <span>{{ getCompanyNameByUserId(user) }}</span>
          </div>
        </div>
        <div class="buttons">
          <button @click="createNewsAndOffer(productSearchInfo.users)">
            Create news & offer
          </button>
          <button @click="createShortlist(productSearchInfo.users)">
            Create shortlist
          </button>
        </div>
      </div>
    </div>

    <div
      class="analytics-product-search-info"
      v-if="productFilterInfo !== false"
      @click="$store.commit('analytics/setProductFilterInfo', false)"
    >
      <div class="analytics-product-search-info-inner" @click.stop>
        <h3>
          Search term: <span>{{ productFilterInfo.filter }}</span>
        </h3>
        <div class="analytics-product-search-info-users">
          <div
            v-for="user in productFilterInfo.users"
            class="analytics-product-search-info-user"
            :key="'search-' + user"
          >
            {{ getUserNameById(user) }}
            <span>{{ getCompanyNameByUserId(user) }}</span>
          </div>
        </div>
        <div class="buttons">
          <button @click="createNewsAndOffer(productFilterInfo.users)">
            Create news & offer
          </button>
          <button @click="createShortlist(productFilterInfo.users)">
            Create shortlist
          </button>
        </div>
      </div>
    </div>
    <div class="analytics-header">
      <h1>Analytics</h1>
      <div class="analytics-options">
        <button class="analytics-reload" @click="reload">
          <span class="material-icons">refresh</span>
          Reload
        </button>
        <div
          class="week-selector"
          @click="selectedWeekOpen = !selectedWeekOpen"
        >
          {{ selectedWeek.label }}
          <span class="material-icons">expand_more</span>
          <div class="week-dropdown" v-if="selectedWeekOpen">
            <div
              v-for="week in weeks"
              :key="week.value"
              class="week-dropdown-item"
              @click.stop="selectWeek(week)"
            >
              {{ week.label }}
              <span
                v-if="week.value == selectedWeek.value"
                class="material-icons"
                >check</span
              >
            </div>
          </div>
        </div>
        <div class="account-owner-selector">
          <div
            class="account-owner-all"
            @click="selectAccountOwner(userVismaId)"
          >
            <div class="radio" :class="{ active: accountOwner != 'all' }"></div>
            My companies
          </div>
          <div class="account-owner-mine" @click="selectAccountOwner('all')">
            <div class="radio" :class="{ active: accountOwner == 'all' }"></div>
            All companies
          </div>
        </div>
        <div class="analytics-type-selector">
          <div
            class="account-owner-all"
            @click="$store.commit('analytics/updateSelectedTypes', 'app')"
          >
            <div
              class="check"
              :class="{ active: selectedTypes.includes('app') }"
            ></div>
            App
          </div>
          <div
            class="account-owner-mine"
            @click="$store.commit('analytics/updateSelectedTypes', 'web')"
          >
            <div
              class="check"
              :class="{ active: selectedTypes.includes('web') }"
            ></div>
            Web
          </div>
        </div>
      </div>
    </div>
    <div v-if="!init">Loading...</div>

    <div v-else class="analytics-wrapper">
      <div class="analytics-widget analytics-added-to-cart">
        <h2>Products added to cart</h2>
        <DoughnutChart
          :chart-data="addedToCartDoughnut"
          :width="200"
          :height="200"
        />
      </div>

      <div class="analytics-widget analytics-search-terms">
        <h2>Product page visits - app only</h2>
        <div class="analytics-scroll-container">
          <div
            v-for="(product, index) in appProductPages"
            :key="'product-page-' + index"
          >
            {{ product.vismaId }} -
            {{ getProductByFirebaseId(product.firebaseId).name }}

            <span class="count">
              {{ product.count }}
            </span>
          </div>
        </div>
      </div>

      <div class="analytics-widget analytics-removed-from-cart">
        <h2>Products removed from cart</h2>
        <DoughnutChart
          :chart-data="removedFromCartDoughnut"
          :width="200"
          :height="200"
        />
      </div>

      <div class="analytics-widget analytics-search-terms">
        <h2>Product searches</h2>
        <div class="analytics-scroll-container">
          <div
            v-for="(search, index) in productSearches"
            :key="'search-' + index"
            @click="showSearchers(search.searchTerm, search.uids)"
          >
            {{ search.searchTerm }}

            <span class="count">
              {{ search.count }}
            </span>
          </div>
        </div>
      </div>

      <div class="analytics-widget analytics-search-terms">
        <h2>Product filters</h2>
        <div class="analytics-scroll-container">
          <div
            v-for="(search, index) in productFilters"
            :key="'search-filter-' + index"
            @click="showFilters(search.filter, search.uids)"
          >
            {{ search.filter }}

            <span class="count">
              {{ search.count }}
            </span>
          </div>
        </div>
      </div>

      <div class="analytics-widget analytics-search-terms">
        <h2>Category icons</h2>
        <div class="analytics-scroll-container">
          <div
            v-for="(search, index) in categoryIcons"
            :key="'search-filter-' + index"
            @click="showFilters(search.filter, search.uids)"
          >
            {{ search.category }}

            <span class="count">
              {{ search.count }}
            </span>
          </div>
        </div>
      </div>

      <div class="analytics-widget analytics-news-and-offers">
        <h2>News and Offers</h2>
        <div class="analytics-news-and-offers-container">
          <div
            v-for="post in newsAndOffersAnalytics"
            :key="'news-and-offers-' + post.id"
            class="news-and-offers-data"
          >
            <h4>{{ post.mainTitle }}</h4>
            <div v-if="post.analytics === undefined">
              No analytics available for post
            </div>

            <div class="news-and-offers-meta" v-else>
              <div>
                <span>Sent to</span>
                <span>{{ post.selections.finalCompanies.length }} företag</span>
              </div>
              <div>
                <span>and to</span>
                <span>{{ post.analytics.totalUserCount }} användare</span>
              </div>
              <div>
                <span>Total opens</span>
                <span>{{
                  post.analytics.pushOpen +
                  post.analytics.chatOpen +
                  post.analytics.newsAndOffersOpen +
                  post.analytics.homePageOpen
                }}</span>
              </div>
              <div>
                <span>Unique opens</span>
                <span>{{ Object.keys(post.analytics.usersOpen).length }}</span>
              </div>
              <div>
                <span>Open from push notification</span>
                <span>{{ post.analytics.pushOpen }}</span>
              </div>
              <div>
                <span>Open from chat</span>
                <span>{{ post.analytics.chatOpen }}</span>
              </div>
              <div>
                <span>Open from news</span>
                <span>{{ post.analytics.newsAndOffersOpen }}</span>
              </div>
              <div>
                <span>Open from home</span>
                <span>{{ post.analytics.homePageOpen }}</span>
              </div>
              <h4>Products added to cart</h4>
              <div
                v-for="product in Object.keys(post.analytics.addToCart)"
                :key="post.id + product"
              >
                {{ getProductByFirebaseId(product).name }}
                <span>{{ post.analytics.addToCart[product] }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import DoughnutChart from "@/components/charts/DoughnutChart";

export default {
  name: "Analytics",
  data() {
    return {
      selectedWeekOpen: false,
      selectedWeek: null,
      weeks: [],
    };
  },
  components: {
    DoughnutChart,
  },
  mounted() {},
  computed: {
    ...mapState({
      init: (state) => state.analytics.init,
      accountOwner: (state) => state.analytics.accountOwner,
      userVismaId: (state) => state.settings.user.vismaId,
      usersWithNoOrders: (state) => state.analytics.usersWithNoOrders,
      newsAndOffersAnalytics: (state) => state.analytics.newsAndOffersAnalytics,
      productSearchInfo: (state) => state.analytics.productSearchInfo,
      productFilterInfo: (state) => state.analytics.productFilterInfo,
      selectedTypes: (state) => state.analytics.selectedTypes,
    }),
    ...mapGetters("analytics", [
      "addedToCartDoughnut",
      "removedFromCartDoughnut",
      "companiesWithoutUsers",
      "companiesWithNeverSignedInUsers",
      "productSearches",
      "productFilters",
      "categoryIcons",
      "appProductPages",
    ]),
    ...mapGetters("products", ["getProductByFirebaseId"]),
    ...mapGetters("users", [
      "getUserNameById",
      "getCompanyNameByUserId",
      "getUserById",
    ]),
  },
  methods: {
    createShortlist: async function (uids) {
      const companies = [];

      uids.forEach((uid) => {
        const user = this.getUserById(uid);
        if (user !== undefined) {
          companies.push(user.companyId);
        }
      });

      // Unique companies
      const uniqueCompanies = [...new Set(companies)];

      console.log(uniqueCompanies);
      await this.$store.dispatch("sharedShortlists/bindShortlists");
      this.$store.commit("sharedShortlists/setInit", true);
      await this.$store.dispatch("sharedShortlists/createShortlist");
      this.$store.commit(
        "sharedShortlists/setSelectedCompanies",
        uniqueCompanies
      );
      this.$store.commit("sharedShortlists/setCurrentStep", 1);
      this.$store.commit("analytics/setProductFilterInfo", false);
      this.$store.commit("analytics/setProductSearchInfo", false);
      this.$router.push({ name: "SharedShortlists" });
    },
    createNewsAndOffer: function (uids) {
      const companies = [];

      uids.forEach((uid) => {
        const user = this.getUserById(uid);
        if (user !== undefined) {
          companies.push(user.companyId);
        }
      });

      // Unique companies
      const uniqueCompanies = [...new Set(companies)];

      console.log(uniqueCompanies);

      this.$store.dispatch("notifications/clearBuilder");
      this.$store.commit("notifications/setNavigation", "create-edit");
      this.$store.commit("notifications/setSelectedCompanies", uniqueCompanies);
      this.$store.commit("notifications/setBuilder", true);
      this.$store.commit("analytics/setProductFilterInfo", false);
      this.$store.commit("analytics/setProductSearchInfo", false);
      this.$router.push({ name: "Notifications" });

      console.log(uids);
    },
    showSearchers: function (term, uids) {
      console.log(term);
      console.log(uids);
      this.$store.commit("analytics/setProductSearchInfo", {
        term: term,
        users: uids,
      });
    },
    showFilters: function (filter, uids) {
      console.log(filter);
      console.log(uids);
      this.$store.commit("analytics/setProductFilterInfo", {
        filter: filter,
        users: uids,
      });
    },
    navigateToCompany: function (id) {
      this.$router.push({ name: "SingleCompany", params: { id } });
    },
    navigateToUser: function (id) {
      this.$router.push({ name: "SingleUser", params: { id } });
    },
    async selectWeek(week) {
      this.selectedWeek = week;
      this.selectedWeekOpen = false;

      this.$store.commit("analytics/setInit", false);
      await this.$store.dispatch("analytics/initNewsAndOffersAnalytics", {
        week: this.selectedWeek.value.toString(),
      });
      await this.$store.dispatch("analytics/initAnalytics", {
        week: this.selectedWeek.value.toString(),
      });
    },
    async reload() {
      this.$store.commit("analytics/setInit", false);
      await this.$store.dispatch("analytics/initNewsAndOffersAnalytics", {
        week: this.selectedWeek.value.toString(),
      });
      await this.$store.dispatch("analytics/initAnalytics", {
        week: this.selectedWeek.value.toString(),
      });
    },
    async selectAccountOwner(owner) {
      this.$store.commit("analytics/setAccountOwner", owner);
      this.$store.commit("analytics/setInit", false);
      await this.$store.dispatch("analytics/initNewsAndOffersAnalytics", {
        week: this.selectedWeek.value.toString(),
      });
      await this.$store.dispatch("analytics/initAnalytics", {
        week: this.selectedWeek.value.toString(),
      });
    },
  },
  async created() {
    let year = this.$moment().format("YYYY");
    let weekNow = this.$moment().isoWeek();
    const initialDate = year.toString() + weekNow.toString();

    for (let i = 0; i < 30; i++) {
      this.weeks.push({
        value: year.toString() + weekNow.toString(),
        label: "Week " + weekNow + ", " + year,
      });

      if (weekNow == 1) {
        year--;
        weekNow = 52;
      } else {
        weekNow--;
      }
    }

    this.selectedWeek = this.weeks.find((week) => week.value == initialDate);

    if (!this.init) {
      this.$store.commit("analytics/setSelectedWeek", this.selectedWeek.value);
      this.$store.commit("analytics/setAccountOwner", "all");
      await this.$store.dispatch("analytics/initNewsAndOffersAnalytics", {
        week: this.selectedWeek.value.toString(),
      });
      await this.$store.dispatch("analytics/initAnalytics", {
        week: this.selectedWeek.value.toString(),
      });
    }
  },
};
</script>
